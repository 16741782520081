import React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Button from "@mui/material/Button";
import { ImageListItem } from "@mui/material";
import navBarLinks from "./navbar_links.json";

import Typography from "@mui/material/Typography";
import { Link, useNavigate } from "react-router-dom";
import jobApplicantServices from "../Services/JobApplicantServices";

function TimerNavbar() {
  const navigate = useNavigate();
  const navItems = navBarLinks.navbar_items;
  const testDuration = localStorage.getItem("testDuration");
  const [time, setTime] = React.useState(testDuration); // 90 minutes in seconds

  React.useEffect(() => {
    const timer = setInterval(() => {
      setTime((prevTime) => {
        if (prevTime === 0) {
          clearInterval(timer);
          handleEndClick();
          // Handle timer completion
        } else if (prevTime === 5 * 60) {
          alert(" Your allocated time is about to end.");
        }
        localStorage.setItem("testDuration", prevTime - 1);
        return prevTime - 1;
      });
    }, 1000); // Update every 1 second

    return () => clearInterval(timer); // Clean up on unmount
  }, []);

  const handleEndClick = async (e) => {
    const applicantId = localStorage.getItem("applicantId");
    const currentDate = new Date();
    const timestamp = currentDate.toISOString();
    const totalMarks = parseInt(localStorage.getItem("totalMarks"));
    const obtainedMarks = parseInt(localStorage.getItem("obtainedMarks"));

    let data = await jobApplicantServices.UpdateJobApplicantMarks({
      id: applicantId,
      timestamp: timestamp,
      totalMarks: totalMarks,
      obtainedMarks: obtainedMarks,
    });

    if (data === false) {
      console.log("Something went wrong.");
    } else {
      if (data.code === 200) {
        console.log(
          "Job Applicant Marks Updated Successfully. ",
          data.responseBody
        );
        window.location.replace("/success");
      } else {
        console.log("Invalid job applicant details.");
      }
    }
  };

  const formatTime = (timeInSeconds) => {
    const hours = Math.floor(timeInSeconds / 3600)
      .toString()
      .padStart(2, "0");
    const minutes = Math.floor((timeInSeconds % 3600) / 60)
      .toString()
      .padStart(2, "0");
    const seconds = (timeInSeconds % 60).toString().padStart(2, "0");

    return `${hours} : ${minutes} : ${seconds}`;
  };

  return (
    <Box
      sx={{ m: 0, p: 0, height: "6vh", display: "flex", alignItems: "center" }}
    >
      {/* <CssBaseline /> */}
      <AppBar
        component="nav"
        sx={{
          backgroundColor: "white",
          color: "teal",
          height: "6vh",
          justifyContent: "center",
        }}
      >
        <Toolbar>
          <ImageListItem>
            <img
              src="/assets/logo.png"
              style={{ height: "30px", width: "120px" }}
            />
          </ImageListItem>

          {/* <Box
            style={{ position: "absolute", right: "0" }}
            sx={{ display: { xs: "none", sm: "block" } }}
          >
            {navItems.map((item) => (
              <Button
                key={item.name}
                href={item.link}
                sx={{
                  color: "teal   ",
                  fontWeight: "bold",
                  marginRight: "20px",
                }}
              >
                {item.name}
              </Button>
            ))}
          </Box> */}
          <Typography
            variant="h5"
            component="div"
            sx={{ flexGrow: 1, textAlign: "right" }}
          >
            {formatTime(time)}
          </Typography>
          {/* <Button color="inherit">End Test</Button> */}
          {/* <Button variant="contained">Contained</Button> */}
          <Button
            variant="contained"
            sx={{
              backgroundColor: "red",
              ml: "20px",
              "&:hover": {
                backgroundColor: "#ff4c4c",
              },
            }}
            onClick={handleEndClick}
          >
            End Test
          </Button>
        </Toolbar>
      </AppBar>
    </Box>
  );
}

export default TimerNavbar;
