import {
  Button,
  Grid,
  ThemeProvider,
  Typography,
  createTheme,
} from "@mui/material";
import React from "react";

const formLabelsTheme = createTheme({
  components: {
    MuiFormLabel: {
      styleOverrides: {
        asterisk: {
          color: "#db3131",
          "&$error": {
            color: "#db3131",
          },
        },
      },
    },
  },
});

function AssessmentInstructions() {
  const handleNextButtonClicked = (e) => {
    localStorage.setItem("testDuration", 90 * 60);
    window.location.replace("/test");
  };

  return (
    <div
      className="container"
      style={{
        display: "flex",
        textAlign: "center",
        justifyContent: "center",
        // backgroundColor: "#b2d8d8",
      }}
    >
      {/* <Grid display='flex' textAlign='center' justifyContent='center' border='1px solid black' > */}
      <Grid
        container
        width="60%"
        display="flex"
        justifyContent="center"
        boxShadow={5}
        borderRadius="5px"
        backgroundColor="white"
        m={5}
      >
        <ThemeProvider theme={formLabelsTheme}>
          <Grid item width="90%" textAlign="justify">
            <Typography
              variant="h6"
              sx={{
                textAlign: "center",
                justifyContent: "center",
                fontWeight: "bold",
                padding: "2% 0 2% 0",
              }}
            >
              Read the Instructions carefully before filling the form.
            </Typography>
            <Typography sx={{ paddingBottom: "2%" }}>
              <li>
                Before proceeding to fill out the form, please take note of the
                following instructions to ensure a smooth submission process:
              </li>

              <li>
                Please ensure that you have a stable internet connection before
                initiating the form-filling process.
              </li>

              <li>
                Use proper capitalization, spelling, and grammar when entering
                your responses. This ensures clarity and professionalism in your
                submission.
              </li>

              <li>
                Fill out all mandatory fields in the form. Missing information
                may result in an incomplete submission.
              </li>

              <li>
                Look for the "Upload" or "Attach" button to provide the
                necessary document attachments. Ensure they meet the specified
                requirements.
              </li>

              <li>
                Ensure that uploaded files are lesser than 10MB in size to meet
                submission requirements.
              </li>

              <li>
                Provide accurate contact details, including your email address
                and phone number. This ensures that you can be reached if there
                are any questions regarding your submission.
              </li>

              <li>
                Before finalizing your submission, carefully review all entries
                for accuracy and completeness. This step is crucial to avoiding
                errors in your form.
              </li>

              <li>
                Once you are confident that all information is accurate, click
                the "Next" button to proceed with your submission.
              </li>
            </Typography>
          </Grid>

          <Grid item width="30%">
            <Button
              variant="contained"
              onClick={handleNextButtonClicked}
              color="success"
              sx={{
                width: "50%",
                p: 2,
                borderRadius: "10px",
                fontSize: 16,
                m: 3,
                display: "inline-flex",
              }}
            >
              START TEST
            </Button>
          </Grid>
        </ThemeProvider>
      </Grid>
    </div>
  );
}

export default AssessmentInstructions;
