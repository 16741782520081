import React, { useEffect } from "react";
import TimerNavbar from "../Component/TimerNavbar";
import TestBody from "./TestBody";

function TestWindow() {
  // const location = useLocation();
  // const state = location.state;
  const assessmentData = localStorage.getItem("assessmentData");
  const state = assessmentData ? JSON.parse(assessmentData) : null;

  useEffect(() => {}, []);

  return (
    <div>
      <TimerNavbar />
      <TestBody assessmentQuestions={state} />
    </div>
  );
}

export default TestWindow;
