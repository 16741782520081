import TextField from "@mui/material/TextField";
import { MuiFileInput } from "mui-file-input";

import React, { useState } from "react";

import Button from "@mui/material/Button";

import FormControl from "@mui/material/FormControl";
import {
  Box,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Typography,
} from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import jobApplicantServices from "../Services/JobApplicantServices";
import assessmentServices from "../Services/AssessmentServices";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";

const formLabelsTheme = createTheme({
  components: {
    MuiFormLabel: {
      styleOverrides: {
        asterisk: {
          color: "#db3131",
          "&$error": {
            color: "#db3131",
          },
        },
      },
    },
  },
});

// const styleItems = {
//   width: "50%",
// };
function JobApplicantInfo() {
  const formElement = {
    width: "100%",
    margin: "10px",
  };

  const formElementGrid = {
    width: "90%",
    display: "inline-flex",
    my: 2,
  };

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNo, setPhoneNo] = useState("");
  const [gender, setGender] = useState("");
  const [dob, setDOB] = useState(null);
  const [city, setCity] = useState("");
  const [degree, setDegree] = useState("");
  const [branch, setBranch] = useState("");
  const [yop, setYOP] = useState("");
  const [cgpaPercentage, setCgpaPercentage] = useState("");
  const [collegeName, setCollegeName] = useState("");
  const [diploma12Yop, setDiploma12Yop] = useState("");
  const [diploma12Percentage, setDiploma12Percentage] = useState("");
  const [diploma12College, setDiploma12College] = useState("");
  const [yop10, setYop10] = useState("");
  const [percentage10, setPercentage10] = useState("");
  const [schoolName10, setSchoolName10] = useState("");
  const [activeBacklog, setActiveBacklog] = useState("");
  const [resumeFile, setResumeFile] = useState(null);

  const [firstNameError, setFirstNameError] = useState("");
  const [lastNameError, setLastNameError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [phoneNoError, setPhoneNoError] = useState("");
  const [genderError, setGenderError] = useState("");
  const [dobError, setDOBError] = useState("");
  const [cityError, setCityError] = useState("");
  const [degreeError, setDegreeError] = useState("");
  const [branchError, setBranchError] = useState("");
  const [yopError, setYOPError] = useState("");
  const [cgpaPercentageError, setCgpaPercentageError] = useState("");
  const [collegeNameError, setCollegeNameError] = useState("");
  const [diploma12YopError, setDiploma12YopError] = useState("");
  const [diploma12PercentageError, setDiploma12PercentageError] = useState("");
  const [diploma12CollegeError, setDiploma12CollegeError] = useState("");
  const [yop10Error, setYop10Error] = useState("");
  const [percentage10Error, setPercentage10Error] = useState("");
  const [schoolName10Error, setSchoolName10Error] = useState("");
  const [activeBacklogError, setActiveBacklogError] = useState("");
  const [resumeFileError, setResumeFileError] = useState(null);
  const [photoFileError, setPhotoFileError] = useState(null);
  const [idFileError, setIdFileError] = useState(null);

  const handleChangeResume = (newFile) => {
    setResumeFileError("");
    setResumeFile(newFile);
  };

  const [photoFile, setPhotoFile] = useState(null);

  const handleChangePhoto = (newFile) => {
    setPhotoFile("");

    setPhotoFile(newFile);
  };

  const [idFile, setIdFile] = useState(null);

  const handleChangeID = (newFile) => {
    setIdFileError("");

    setIdFile(newFile);
  };

  //Restrict All textField
  const handleFirstNameChange = (event) => {
    const { value } = event.target; // Only allow alpha characters
    const newValue = value.replace(/[^A-Za-z ]/g, "");
    setFirstName(newValue);
    setFirstNameError("");
  };
  const handlelastNameChange = (event) => {
    const { value } = event.target; // Only allow alpha characters
    const newValue = value.replace(/[^A-Za-z ]/g, "");
    setLastName(newValue);
    setLastNameError("");
  };
  const handleEmailchange = (event) => {
    const { value } = event.target; // Only allow alphanumeric and Special  characters
    const newValue = value.replace(/[^A-Za-z0-9@._]/g, "");
    setEmail(newValue);
    setEmailError("");
  };
  // const handlePhoneNochange = (event) => {
  //   const { value } = event.target; // Only allow numeric characters
  //   const newValue = value.replace(/[^0-9]/g, "");

  //   setPhoneNo(newValue);
  // };
  const handlePhoneNochange = (event) => {
    const { value } = event.target;
    // Only allow numeric characters and limit to 10 characters
    const newValue = value.replace(/[^0-9]/g, "").slice(0, 10);
    setPhoneNoError("");
    setPhoneNo(newValue);
  };
  const handleGenderChange = (event) => {
    const { value } = event.target;
    setGender(value);
    setGenderError("");
  };
  const handleDOBChange = (value) => {
    // const { value } = event.target;
    setDOB(value);
    setDOBError("");
  };
  const handleCitychange = (event) => {
    const { value } = event.target; // Only allow alpha characters
    const newValue = value.replace(/[^A-Za-z]/g, "");
    setCity(newValue);
    setCityError("");
  };
  const handleDegreechange = (event) => {
    const { value } = event.target; // Only allow alpha characters
    const newValue = value.replace(/[^A-Za-z ]/g, "");
    setDegree(newValue);
    setDegreeError("");
  };
  const handleBranchchange = (event) => {
    const { value } = event.target; // Only allow alpha characters
    const newValue = value.replace(/[^A-Za-z .]/g, "");
    setBranch(newValue);
    setBranchError("");
  };
  const handleYOPchange = (event) => {
    const { value } = event.target; // Only allow numeric characters
    //const newValue = value.replace(/[^0-9]/g, "");
    const newValue = value.replace(/[^0-9]/g, "").slice(0, 4);
    setYOP(newValue);
    setYOPError("");
  };

  const handlecgpaPercentagechange = (event) => {
    const { value } = event.target; // Only allow alphanumeric characters
    //const newValue = value.replace(/[^A-Za-z0-9%]/g, "");
    const newValue = value.replace(/[^0-9%.]/g, "").slice(0, 5);
    setCgpaPercentage(newValue);
    setCgpaPercentageError("");
  };
  const handleCollegeNamechange = (event) => {
    const { value } = event.target; // Only allow alpha characters
    const newValue = value.replace(/[^A-Za-z ]/g, "");
    setCollegeName(newValue);
    setCollegeNameError("");
  };
  const handleDiploma12Yopchange = (event) => {
    const { value } = event.target; // Only allow numeric characters
    // const newValue = value.replace(/[^0-9]/g, "");
    const newValue = value.replace(/[^0-9]/g, "").slice(0, 4);
    setDiploma12Yop(newValue);
    setDiploma12Yop(newValue);
  };
  const handleDiploma12Percentagechange = (event) => {
    const { value } = event.target; // Only allow alphaNumeric and special  characters
    //const newValue = value.replace(/[^A-Za-z0-9%]/g, "");
    const newValue = value.replace(/[^0-9%.]/g, "").slice(0, 5);
    setDiploma12Percentage(newValue);
    setDiploma12Percentage(newValue);
  };
  const handlediploma12Collegechange = (event) => {
    const { value } = event.target; // Only allow alpha characters
    const newValue = value.replace(/[^A-Za-z ]/g, "");
    setDiploma12College(newValue);
    setDiploma12CollegeError("");
  };
  const handleYop10change = (event) => {
    const { value } = event.target; // Only allow numeric characters
    //const newValue = value.replace(/[^0-9]/g, "");
    const newValue = value.replace(/[^0-9]/g, "").slice(0, 4);
    setYop10(newValue);
    setYop10Error("");
  };
  const handlePercentage10change = (event) => {
    const { value } = event.target; // Only allow alphanumeric and special characters
    // const newValue = value.replace(/[^A-Za-z0-9%]/g, "");
    const newValue = value.replace(/[^0-9%.]/g, "").slice(0, 5);
    setPercentage10(newValue);
    setPercentage10Error("");
  };
  const handleSchoolName10change = (event) => {
    const { value } = event.target; // Only allow alpha characters
    const newValue = value.replace(/[^A-Za-z ]/g, "");
    setSchoolName10(newValue);
    setSchoolName10Error("");
  };
  const handleactiveBacklogchange = (event) => {
    const { value } = event.target; // Only allow numeric characters
    //const newValue = value.replace(/[^0-2]/g, "");
    const newValue = value.replace(/[^0-9]/g, "").slice(0, 2);
    setActiveBacklog(newValue);
    setActiveBacklogError("");
  };
  const validateFields = (e) => {
    setFirstNameError(firstName ? "" : "This field is required");
    setLastNameError(lastName ? "" : "This field is required");
    setEmailError(email ? "" : "This field is required");
    setPhoneNoError(phoneNo ? "" : "This field is required");
    setGenderError(gender ? "" : "This field is required");
    setDOBError(dob != null ? "" : "This field is required");
    setCityError(city ? "" : "This field is required");
    setDegreeError(degree ? "" : "This field is required");
    setBranchError(branch ? "" : "This field is required");
    setYOPError(yop ? "" : "This field is required");
    setCgpaPercentageError(cgpaPercentage ? "" : "This field is required");
    setCollegeNameError(collegeName ? "" : "This field is required");
    setDiploma12YopError(diploma12Yop ? "" : "This field is required");
    setDiploma12PercentageError(
      diploma12Percentage ? "" : "This field is required"
    );
    setDiploma12CollegeError(diploma12College ? "" : "This field is required");
    setYop10Error(yop10 ? "" : "This field is required");
    setPercentage10Error(percentage10 ? "" : "This field is required");
    setSchoolName10Error(schoolName10 ? "" : "This field is required");
    setActiveBacklogError(activeBacklog ? "" : "This field is required");

    setResumeFileError(resumeFile ? "" : "This field is required");
    setPhotoFileError(photoFile ? "" : "This field is required");
    setIdFileError(idFile ? "" : "This field is required");

    const isFormValid =
      firstName &&
      lastName &&
      email &&
      phoneNo &&
      dob != null &&
      city &&
      degree &&
      branch &&
      yop &&
      cgpaPercentage &&
      collegeName &&
      diploma12Yop &&
      diploma12Percentage &&
      diploma12College &&
      yop10 &&
      percentage10 &&
      schoolName10 &&
      activeBacklog &&
      resumeFile &&
      photoFile &&
      idFile;

    return isFormValid;
  };

  const resetValues = (e) => {
    setFirstName("");
    setLastName("");
    setEmail("");
    setPhoneNo("");
    setGender("");
    setDOB(null);
    setCity("");
    setDegree("");
    setBranch("");
    setYOP("");
    setCgpaPercentage("");
    setCollegeName("");
    setDiploma12Yop("");
    setDiploma12Percentage("");
    setDiploma12College("");
    setYop10("");
    setPercentage10("");
    setSchoolName10("");
    setActiveBacklog("");
    setResumeFile(null);
    setPhotoFile(null);
    setIdFile(null);
  };

  const formatDate = (isoDateString) => {
    const [year, month, day] = isoDateString.split("-");
    return `${day}-${month}-${year}`;
  };

  const handleNextButtonClicked = async (event) => {
    if (validateFields()) {
      const files = [resumeFile, photoFile, idFile];
      console.log(files);
      const currentDate = new Date();
      const timestamp = currentDate.toISOString();
      const formattedDOB = dob.format("DD-MM-YYYY");
      const jsonData = {
        name: firstName + " " + lastName,
        email: email,
        phoneNo: phoneNo,
        gender: gender,
        dob: formattedDOB,
        city: city,
        degree: degree,
        branch: branch,
        yop: yop,
        cgpa: cgpaPercentage,
        college_Name: collegeName,
        yop_12: diploma12Yop,
        percentage_12: diploma12Percentage,
        college_12: diploma12College,
        yop_10: yop10,
        percentage_10: percentage10,
        school_10: schoolName10,
        active_Backlog: activeBacklog,
        timestamp: timestamp,
      };
      let data = await jobApplicantServices.AddJobApplicant(files, jsonData);

      if (data === false) {
        console.log("Something went wrong.");
      } else {
        if (data.code === 200) {
          console.log("Job Applicant Added Successfully. ", data.responseBody);
          localStorage.setItem("applicantId", data.responseBody.id);
          getAssessmentQuestions();
          // resetValues();
        } else {
          console.log("Invalid job applicant details.");
        }
      }
      // window.location.replace("/test");
    }
  };

  const getAssessmentQuestions = async () => {
    let assessmentData = await assessmentServices.getAssessmentQuestions(
      "java"
    );
    if (assessmentData === false) {
      console.log("Something went wrong.");
    } else {
      if (assessmentData.code === 200) {
        console.log(
          "Assessment Questions Received Successfully. ",
          assessmentData.responseBody
        );
        resetValues();
        localStorage.setItem(
          "assessmentData",
          JSON.stringify(assessmentData.responseBody)
        );
        window.location.replace("/rules");
        // navigate("/test", { state: assessmentData.responseBody });
      } else {
        console.log("Error getting assessment questions.");
      }
    }
  };

  return (
    <div
      className="container"
      style={{
        display: "flex",
        textAlign: "center",
        justifyContent: "center",
        // backgroundColor: "#b2d8d8",
      }}
    >
      {/* <Grid display='flex' textAlign='center' justifyContent='center' border='1px solid black' > */}
      <Grid
        container
        width="60%"
        display="flex"
        justifyContent="center"
        border="1px solid lightgrey"
        borderRadius="5px"
        backgroundColor="white"
        sx={{ boxShadow: 5, m: 5, pl: 5 }}
      >
        <ThemeProvider theme={formLabelsTheme}>
          <Grid item width="70%">
            <Grid container p={2}>
              <Grid item sx={formElementGrid}>
                <Grid container width="100%" display={"inline-flex"}>
                  <Grid width="50%" display={"flex"}>
                    <TextField
                      required
                      id="name"
                      label="First Name"
                      sx={{ width: "95%" }}
                      variant="standard"
                      value={firstName}
                      onChange={handleFirstNameChange}
                      error={Boolean(firstNameError)}
                      helperText={firstNameError}
                      onBlur={() => {
                        setFirstNameError(
                          firstName ? "" : "This field is required"
                        );
                      }}
                    />
                  </Grid>
                  <Grid width="50%" display={"flex"} justifyContent={"right"}>
                    <TextField
                      required
                      id="name"
                      label="Last Name"
                      sx={{ width: "95%" }}
                      variant="standard"
                      value={lastName}
                      onChange={handlelastNameChange}
                      error={Boolean(lastNameError)}
                      helperText={lastNameError}
                      onBlur={() => {
                        setLastNameError(
                          lastName ? "" : "This field is required"
                        );
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>

              <Grid item sx={formElementGrid}>
                <Grid container width="100%" display={"inline-flex"}>
                  <Grid width="60%" display={"flex"}>
                    <TextField
                      required
                      id="email"
                      label="E-mail"
                      type="email"
                      sx={{ width: "95%" }}
                      variant="standard"
                      value={email}
                      onChange={handleEmailchange}
                      error={Boolean(emailError)}
                      helperText={emailError}
                      onBlur={() => {
                        setEmailError(email ? "" : "This field is required");
                      }}
                    />
                  </Grid>

                  <Grid width="40%" display={"flex"} justifyContent={"right"}>
                    <TextField
                      required
                      id="phoneNo"
                      label="Phone No."
                      sx={{ width: "95%" }}
                      variant="standard"
                      value={phoneNo}
                      onChange={handlePhoneNochange}
                      error={Boolean(phoneNoError)}
                      helperText={phoneNoError}
                      onBlur={() => {
                        setPhoneNoError(
                          phoneNo ? "" : "This field is required"
                        );
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>

              <Grid item sx={formElementGrid}>
                <FormControl
                  variant="standard"
                  sx={{ width: "40%" }}
                  error={Boolean(genderError)}
                >
                  <InputLabel required id="genderLabel">
                    Gender
                  </InputLabel>
                  <Select
                    required
                    labelId="genderLabel"
                    label="Select Gender"
                    sx={{ textAlign: "left" }}
                    value={gender}
                    onChange={handleGenderChange}
                    onBlur={() => {
                      setGenderError(gender ? "" : "This field is required");
                    }}
                  >
                    <MenuItem value={"Male"}>Male</MenuItem>
                    <MenuItem value={"Female"}>Female</MenuItem>
                    <MenuItem value={"Others"}>Others</MenuItem>
                  </Select>

                  <Typography
                    sx={{
                      fontSize: "12.25px",
                      color: "red",
                      display: "flex",
                      alignItems: "flex-start",
                      mt: "1%",
                    }}
                  >
                    {genderError}
                  </Typography>
                </FormControl>
              </Grid>

              <Grid item sx={formElementGrid}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    id="dob"
                    required
                    format="DD-MM-YYYY"
                    value={dob}
                    label="Date of Birth"
                    sx={{ width: "60%" }}
                    onChange={handleDOBChange}
                    onBlur={() => {
                      setDOBError(dob != null ? "" : "This field is required");
                    }}
                    slotProps={{
                      textField: {
                        required: true,
                        variant: "standard",
                        helperText: dobError,
                        error: Boolean(dobError),
                      },
                    }}
                  />
                </LocalizationProvider>
              </Grid>

              <Grid item sx={formElementGrid}>
                <TextField
                  required
                  id="city"
                  label="City"
                  //helperText="Some important text"
                  sx={{ width: "60%" }}
                  variant="standard"
                  value={city}
                  onChange={handleCitychange}
                  error={Boolean(cityError)}
                  helperText={cityError}
                  onBlur={() => {
                    setCityError(city ? "" : "This field is required");
                  }}
                />
              </Grid>

              <Grid item sx={formElementGrid}>
                <Grid container width="100%" display={"inline-flex"}>
                  <Grid width="50%" display={"flex"}>
                    <TextField
                      required
                      id="degree"
                      label="Degree"
                      // helperText="Some important text"
                      sx={{ width: "95%" }}
                      variant="standard"
                      value={degree}
                      onChange={handleDegreechange}
                      error={Boolean(degreeError)}
                      helperText={degreeError}
                      onBlur={() => {
                        setDegreeError(degree ? "" : "This field is required");
                      }}
                    />
                  </Grid>

                  <Grid width="50%" display={"flex"} justifyContent={"right"}>
                    <TextField
                      required
                      id="branch"
                      label="Branch"
                      // helperText="Some important text"
                      sx={{ width: "95%" }}
                      variant="standard"
                      value={branch}
                      onChange={handleBranchchange}
                      error={Boolean(branchError)}
                      helperText={branchError}
                      onBlur={() => {
                        setBranchError(branch ? "" : "This field is required");
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>

              <Grid item sx={formElementGrid}>
                <Grid container width="100%" display={"inline-flex"}>
                  <Grid width="50%" display={"flex"}>
                    <TextField
                      required
                      id="yop"
                      label="Year of Pass Out"
                      //helperText="Some important text"
                      sx={{ width: "95%" }}
                      variant="standard"
                      value={yop}
                      onChange={handleYOPchange}
                      error={Boolean(yopError)}
                      helperText={yopError}
                      onBlur={() => {
                        setYOPError(yop ? "" : "This field is required");
                      }}
                    />
                  </Grid>

                  <Grid width="50%" display={"flex"} justifyContent={"right"}>
                    <TextField
                      required
                      id="cgpa"
                      label="CGPA/Percentage"
                      //helperText="Some important text"
                      sx={{ width: "95%" }}
                      variant="standard"
                      value={cgpaPercentage}
                      onChange={handlecgpaPercentagechange}
                      error={Boolean(cgpaPercentageError)}
                      helperText={cgpaPercentageError}
                      onBlur={() => {
                        setCgpaPercentageError(
                          cgpaPercentage ? "" : "This field is required"
                        );
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>

              <Grid item sx={formElementGrid}>
                <TextField
                  required
                  id="college_Name"
                  label="College Name"
                  //helperText="Some important text"
                  sx={{ width: "100%" }}
                  variant="standard"
                  value={collegeName}
                  onChange={handleCollegeNamechange}
                  error={Boolean(collegeNameError)}
                  helperText={collegeNameError}
                  onBlur={() => {
                    setCollegeNameError(
                      collegeName ? "" : "This field is required"
                    );
                  }}
                />
              </Grid>

              <Grid item sx={formElementGrid}>
                <Grid container width="100%" display={"inline-flex"}>
                  <Grid width="50%" display={"flex"}>
                    <TextField
                      required
                      id="yop_12"
                      label="12th/Diploma Year of Pass Out"
                      // helperText="Some important text"
                      sx={{ width: "95%" }}
                      variant="standard"
                      value={diploma12Yop}
                      onChange={handleDiploma12Yopchange}
                      error={Boolean(diploma12YopError)}
                      helperText={diploma12YopError}
                      onBlur={() => {
                        setDiploma12YopError(
                          diploma12Yop ? "" : "This field is required"
                        );
                      }}
                    />
                  </Grid>

                  <Grid width="50%" display={"flex"} justifyContent={"right"}>
                    <TextField
                      required
                      id="percentage_12"
                      label="12th/Diploma Percentage"
                      //helperText="Some important text"
                      sx={{ width: "95%" }}
                      variant="standard"
                      value={diploma12Percentage}
                      onChange={handleDiploma12Percentagechange}
                      error={Boolean(diploma12PercentageError)}
                      helperText={diploma12PercentageError}
                      onBlur={() => {
                        setDiploma12PercentageError(
                          diploma12Percentage ? "" : "This field is required"
                        );
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>

              <Grid item sx={formElementGrid}>
                <TextField
                  required
                  id="college_12"
                  label="12th/Diploma College Name"
                  //helperText="Some important text"
                  sx={{ width: "100%" }}
                  variant="standard"
                  value={diploma12College}
                  onChange={handlediploma12Collegechange}
                  error={Boolean(diploma12CollegeError)}
                  helperText={diploma12CollegeError}
                  onBlur={() => {
                    setDiploma12CollegeError(
                      diploma12College ? "" : "This field is required"
                    );
                  }}
                />
              </Grid>

              <Grid item sx={formElementGrid}>
                <Grid container width="100%" display={"inline-flex"}>
                  <Grid width="50%" display={"flex"}>
                    <TextField
                      required
                      id="yop_10"
                      label="10th Year of Pass Out"
                      //helperText="Some important text"
                      sx={{ width: "95%" }}
                      variant="standard"
                      value={yop10}
                      onChange={handleYop10change}
                      error={Boolean(yop10Error)}
                      helperText={yop10Error}
                      onBlur={() => {
                        setYop10Error(yop10 ? "" : "This field is required");
                      }}
                    />
                  </Grid>

                  <Grid width="50%" display={"flex"}>
                    <TextField
                      required
                      id="percentage_10"
                      label="10th Percentage"
                      //helperText="Some important text"
                      sx={{ width: "95%" }}
                      variant="standard"
                      value={percentage10}
                      onChange={handlePercentage10change}
                      error={Boolean(percentage10Error)}
                      helperText={percentage10Error}
                      onBlur={() => {
                        setPercentage10Error(
                          percentage10 ? "" : "This field is required"
                        );
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>

              <Grid item sx={formElementGrid}>
                <TextField
                  required
                  id="school_10"
                  label="10th School Name"
                  //helperText="Some important text"
                  sx={{ width: "100%" }}
                  variant="standard"
                  value={schoolName10}
                  onChange={handleSchoolName10change}
                  error={Boolean(schoolName10Error)}
                  helperText={schoolName10Error}
                  onBlur={() => {
                    setSchoolName10Error(
                      schoolName10 ? "" : "This field is required"
                    );
                  }}
                />
              </Grid>

              <Grid item sx={formElementGrid}>
                <TextField
                  required
                  id="active_Backlog"
                  label="Active Backlogs"
                  // helperText="Some important text"
                  sx={{ width: "60%" }}
                  variant="standard"
                  value={activeBacklog}
                  onChange={handleactiveBacklogchange}
                  error={Boolean(activeBacklogError)}
                  helperText={activeBacklogError}
                  onBlur={() => {
                    setActiveBacklogError(
                      activeBacklog ? "" : "This field is required"
                    );
                  }}
                />
              </Grid>

              {/* <Grid item sx={formElementGrid}>
                <Typography variant="h6" fontWeight={"bold"} display="flex">
                  Documents
                </Typography>
              </Grid> */}

              <Grid item sx={formElementGrid}>
                <Grid container width="100%" display={"inline-flex"}>
                  <Grid width="50%" display={"flex"}>
                    <MuiFileInput
                      required
                      variant="standard"
                      id="resume"
                      value={resumeFile}
                      onChange={handleChangeResume}
                      label="Upload you resume"
                      sx={{ width: "95%", fontSize: 8 }}
                      error={Boolean(resumeFileError)}
                      helperText={resumeFileError}
                      onBlur={() => {
                        setResumeFileError(
                          resumeFile ? "" : "This field is required"
                        );
                      }}
                    />
                  </Grid>

                  <Grid width="50%" display={"flex"} justifyContent={"right"}>
                    <MuiFileInput
                      required
                      variant="standard"
                      id="photo"
                      value={photoFile}
                      onChange={handleChangePhoto}
                      label="Upload you photo"
                      sx={{ width: "95%" }}
                      error={Boolean(photoFileError)}
                      helperText={photoFileError}
                      onBlur={() => {
                        setPhotoFileError(
                          photoFile ? "" : "This field is required"
                        );
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>

              <Grid item sx={formElementGrid} justifyContent={"center"}>
                <MuiFileInput
                  required
                  variant="standard"
                  id="id_Proof"
                  value={idFile}
                  onChange={handleChangeID}
                  label="Upload you ID Proof"
                  sx={{ width: "50%" }}
                  error={Boolean(idFileError)}
                  helperText={idFileError}
                  onBlur={() => {
                    setIdFileError(idFile ? "" : "This field is required");
                  }}
                />
              </Grid>

              <Grid
                item
                sx={formElementGrid}
                display={"flex"}
                justifyContent={"center"}
              >
                <Button
                  variant="contained"
                  sx={{
                    width: "50%",
                    borderRadius: 2,
                    py: 2,
                  }}
                  onClick={handleNextButtonClicked}
                >
                  <Typography variant="body1" fontWeight={"bold"}>
                    NEXT
                  </Typography>
                </Button>
              </Grid>
            </Grid>
          </Grid>
          <Grid item width="30%">
            <Grid
              sx={{
                textAlign: "left",
                backgroundColor: "lightgrey",
                padding: 2,
                height: "100%",
              }}
            >
              <Box sx={{ textTransform: "uppercase", my: 3, px: 1 }}>
                <Typography variant="body2" fontWeight={"bold"}>
                  Read the Instructions carefully before filling the form.
                </Typography>
              </Box>
              <Box sx={{ px: 2 }}>
                <Typography fontSize={12}>
                  <li style={{ marginBottom: "12px" }}>
                    Before proceeding to fill out the form, please take note of
                    the following instructions to ensure a smooth submission
                    process:
                  </li>
                  <li style={{ marginBottom: "12px" }}>
                    Please ensure that you have a stable internet connection
                    before initiating the form-filling process.
                  </li>
                  <li style={{ marginBottom: "12px" }}>
                    Use proper capitalization, spelling, and grammar when
                    entering your responses. This ensures clarity and
                    professionalism in your submission.
                  </li>
                  <li style={{ marginBottom: "12px" }}>
                    Fill out all mandatory fields in the form. Missing
                    information may result in an incomplete submission.
                  </li>
                  <li style={{ marginBottom: "12px" }}>
                    Look for the "Upload" or "Attach" button to provide the
                    necessary document attachments. Ensure they meet the
                    specified requirements.
                  </li>
                  <li style={{ marginBottom: "12px" }}>
                    Ensure that uploaded files are lesser than 10MB in size to
                    meet submission requirements.
                  </li>
                  <li style={{ marginBottom: "12px" }}>
                    Provide accurate contact details, including your email
                    address and phone number. This ensures that you can be
                    reached if there are any questions regarding your
                    submission.
                  </li>
                  <li style={{ marginBottom: "12px" }}>
                    Before finalizing your submission, carefully review all
                    entries for accuracy and completeness. This step is crucial
                    to avoiding errors in your form.
                  </li>
                  <li style={{ marginBottom: "12px" }}>
                    Once you are confident that all information is accurate,
                    click the "Next" button to proceed with your submission.
                  </li>
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </ThemeProvider>
      </Grid>
      {/* </Grid>  */}
    </div>
  );
}

export default JobApplicantInfo;
