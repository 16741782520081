import { Box, Button, Grid, Tab, Tabs } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import TestQuestion from "./TestQuestion";

const tabStyle = {
  fontWeight: "bold",
  width: "100%",
  display: "block",
  fontSize: 20,
  py: 2,
  "&. MuiTabs-indicator": {
    display: "none",
  },
  "&.Mui-selected": {
    backgroundColor: "lightgrey",
    transition: "0.5s",
    borderRight: 2.5,
  },
};

function TestBody({ assessmentQuestions }) {
  const questionSection = useRef(null);
  // const javaAssessemntQuestions = javaJson.responseBody;
  const questionType = [
    assessmentQuestions.aptitude,
    assessmentQuestions.logical,
    assessmentQuestions.verbal,
    assessmentQuestions.technical,
  ];
  const [tabValue, setTabValue] = useState(0);

  const totalMarks =
    assessmentQuestions.aptitude.length +
    assessmentQuestions.logical.length +
    assessmentQuestions.verbal.length +
    assessmentQuestions.technical.length;
  localStorage.setItem("totalMarks", totalMarks);

  const [selectedOptions, setSelectedOptions] = useState(
    questionType.map((questionSet) => Array(questionSet.length).fill(null))
  );
  const correctOptions = questionType.map((questionSet) =>
    questionSet.map((question) => question.correctAnswer)
  );

  const handleOptionChange = (questionIndex, value) => {
    const newSelectedOptions = [...selectedOptions];
    newSelectedOptions[tabValue][questionIndex] = value;
    setSelectedOptions(newSelectedOptions);
    console.log(selectedOptions);
    console.log(correctOptions);
    checkScore();
  };

  const checkScore = () => {
    const score = selectedOptions.flat().reduce((count, element, index) => {
      return count + (element === correctOptions.flat()[index]);
    }, 0);
    localStorage.setItem("obtainedMarks", score);
    console.log("SCORE: " + score);
  };

  const handleChangeTab = (e, v) => {
    setTabValue(v);
  };

  useEffect(() => {
    if (questionSection.current) {
      questionSection.current.scrollTop = 0; // Scroll to the top of the right grid
    }
  }, [tabValue]);

  const [disableNextSectionButton, setDisableNextSectionButton] =
    useState(false);

  return (
    <Grid container display={"flex"}>
      <Grid item xs={2}>
        <Box
          sx={{
            overflow: "auto",
            borderRight: 1,
            borderColor: "grey",
            height: "92vh",
          }}
        >
          <Tabs
            orientation="vertical"
            value={tabValue}
            onChange={handleChangeTab}
          >
            <Tab label="Aptitude" value={0} sx={tabStyle} />
            <Tab label="Logical" value={1} sx={tabStyle} />
            <Tab label="Verbal" value={2} sx={tabStyle} />
            <Tab label="Technical" value={3} sx={tabStyle} />
          </Tabs>
        </Box>
      </Grid>
      <Grid item xs={10}>
        <Box sx={{ overflowY: "auto", height: "92vh" }} ref={questionSection}>
          {questionType[tabValue].map((item, index) => (
            <TestQuestion
              index={index}
              data={item}
              onChange={handleOptionChange}
              state={selectedOptions[tabValue][index]}
            />
          ))}
          <Box
            sx={{
              display: "flex",
              justifyContent: "right",
              pr: "4%",
              pb: "1%",
            }}
          >
            <Button
              sx={{ fontSize: "16px", borderRadius: 0 }}
              disabled={tabValue === 3 ? true : false}
              variant="contained"
              onClick={() => {
                // if (tabValue >= 2) {
                //   setDisableNextSectionButton(true);
                // }
                setTabValue(tabValue + 1);
              }}
            >
              Next Section
            </Button>
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
}

export default TestBody;
