import axios from "axios";
import { apiEndpoint } from "../config";

let axiosConfig = {
  withCredentials: true,
};

class JobApplicantServices {
  async AddJobApplicant(files, jsonData) {
    try {
      const formData = new FormData();
      for (let i = 0; i < files.length; i++) {
        formData.append("documents", files[i]);
      }

      formData.append("data", JSON.stringify(jsonData));

      const response = await axios.post(
        `${apiEndpoint}/form/jobApplicant/add`,
        formData,
        axiosConfig
      );
      return response.data;
    } catch (error) {
      console.log("error ", error);
      return false;
    }
  }

  async UpdateJobApplicantMarks(data) {
    try {
      const response = await axios.post(
        `${apiEndpoint}/form/jobApplicant/updateMarks`,
        data,
        axiosConfig
      );
      return response.data;
    } catch (error) {
      console.log("error " + error);
      return false;
    }
  }
}

const jobApplicantServices = new JobApplicantServices();
export default jobApplicantServices;
