import "./App.css";
import JobApplicantInfo from "./AssessmentModule/JobApplicantInfo";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import TestWindow from "./AssessmentModule/TestWindow";
import TimerNavbar from "./Component/TimerNavbar";
import TestBody from "./AssessmentModule/TestBody";
import TestQuestion from "./AssessmentModule/TestQuestion";
import ThankYouMessage from "./AssessmentModule/ThankYouMessage";
import AssessmentInstructions from "./AssessmentModule/AssessmentInstructions";
import AssessmentModule from "./AssessmentModule/AssessmentModule";

function App() {
  return (
    <BrowserRouter>
      <div>
        {/* <TimerNavbar />
        <TestBody /> */}
        <Routes>
          <Route path="/" element={<AssessmentModule />}>
            <Route path="" element={<JobApplicantInfo />} />
            <Route path="rules" element={<AssessmentInstructions />} />
          </Route>
          <Route path="/test" element={<TestWindow />} />
          <Route path="/success" element={<ThankYouMessage />} />
        </Routes>
      </div>
    </BrowserRouter>
  );
}

export default App;
