import { Radio, RadioGroup, Sheet } from "@mui/joy";
import { Box, Typography } from "@mui/material";
import React from "react";

function TestQuestion({ index, data, onChange, state }) {
  const optionValues = [data.optionA, data.optionB, data.optionC, data.optionD];
  const options = ["A", "B", "C", "D"];

  const handleRadioChange = (e) => {
    onChange(index, e.target.value);
  };

  return (
    <div>
      <Box sx={{ border: 0, mt: 5, mx: 10, mb: 10 }}>
        <Box sx={{ display: "flex" }}>
          <Typography
            variant="plain"
            fontSize={18}
            fontWeight="500"
            sx={{ mr: 2 }}
          >
            {index + 1}.
          </Typography>
          <Typography variant="plain" fontSize={18} fontWeight="500">
            {data.questionDescription
              .replace(/^"|"$|\n$/g, "")
              .split("\n")
              .map((line, index) => (
                <div style={{ marginBottom: index === 0 ? "5px" : "0" }}>
                  {line.replace(/""/g, '"')}
                  <br />
                </div>
              ))}
          </Typography>
        </Box>

        <RadioGroup
          sx={{
            gap: 1.5,
            mt: 1.5,
            mx: 3,
          }}
          value={state}
          onChange={handleRadioChange}
        >
          {optionValues.map((value, index) => (
            <Sheet
              // key={value}
              sx={{
                p: 2,
                borderRadius: "md",
                boxShadow: 2,
                display: "flex",
              }}
            >
              <Radio
                label={options[index] + ". " + value}
                overlay
                disableIcon
                value={value}
                slotProps={{
                  label: ({ checked }) => ({
                    sx: {
                      fontWeight: "lg",
                      fontSize: "md",
                      color: checked ? "text.primary" : "text.secondary",
                    },
                  }),
                  action: ({ checked }) => ({
                    sx: (theme) => ({
                      ...(checked && {
                        borderWidth: "2px",
                        "&&": {
                          borderColor: "green",
                          backgroundColor: "lightgrey",
                        },
                      }),
                    }),
                  }),
                }}
              />
            </Sheet>
          ))}
        </RadioGroup>
      </Box>
    </div>
  );
}

export default TestQuestion;
