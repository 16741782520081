const styles = {
  container: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "94vh",
  },
  centered: {
    textAlign: "center",
  },
};

function ThankYouMessage() {
  return (
    <div style={styles.container}>
      <div style={styles.centered}>
        <h2>Thanks for submitting the test!</h2>
        <p>You may safely close this tab now.</p>
      </div>
    </div>
  );
}

export default ThankYouMessage;
