import axios from "axios";
import { apiEndpoint } from "../config";

let axiosConfig = {
  withCredentials: true,
};

class AssessmentServices {
  async getAssessmentQuestions(assessmentType) {
    try {
      const response = await axios.get(
        `${apiEndpoint}/test/list/${assessmentType}`,
        {},
        axiosConfig
      );
      return response.data;
    } catch (error) {
      console.log("error ", error);
      return false;
    }
  }
}

const assessmentServices = new AssessmentServices();
export default assessmentServices;
